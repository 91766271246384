import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef, Fragment } from 'react';
import Tooltip from '../Tooltip';

const BaseComponent = ({ children, tooltip }) =>
	tooltip ? <Tooltip title={tooltip}>{children}</Tooltip> : <Fragment>{children}</Fragment>;

const Tag = forwardRef((props, ref) => {
	const { className, children, prefix, suffix, prefixClass, suffixClass, tooltip, ...rest } = props;

	return (
		<BaseComponent tooltip={tooltip}>
			<div className={classNames('tag', className)} ref={ref} {...rest}>
				{prefix && typeof prefix === 'boolean' && <span className={classNames('tag-affix tag-prefix', prefixClass)} />}
				{typeof prefix === 'object' && prefix}
				{children}
				{suffix && typeof suffix === 'boolean' && <span className={classNames('tag-affix tag-suffix', suffixClass)} />}
				{typeof suffix === 'object' && suffix}
			</div>
		</BaseComponent>
	);
});

Tag.defaultProps = {
	prefix: false,
	suffix: false,
};

Tag.propTypes = {
	prefix: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
	suffix: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
	prefixClass: PropTypes.string,
};

export default Tag;
